# @owner @Appboy/frontend-ix

import $ from 'jquery'
import { I18n } from '@lib/i18n'
import { Validator as validator } from 'lib/ab-validator-util'
import Parsley from 'parsleyjs'
import notificationConstants from 'lib/constants/notification-constants.ko'
import featureFlipper from 'lib/util/feature-flipper'

i18n = I18n.namespace("ab-parsley-addons")
HTTP_REGEX = /https?:\/\/.+\..+/i
PEP_FLAG_ON_CEP_ERROR = i18n.t("canvas-entry-properties-error-pep-flag-on", "Canvas Entry Properties can only be used in API-triggered Canvases or Action-based Canvases triggered by purchase or custom events only.")
PEP_FLAG_OFF_CEP_ERROR = i18n.t("canvas-entry-properties-error-pep-flag-off", "Canvas Entry Properties can only be used in the first step of action-based or api-triggered Canvases, or when using Canvas Flow.")

# Exclude these fields from being validated. The data-parsley-exclude option does not appear to be working in our version of
# parsley, so pass the exclude options directly to the config. Use data-parsley-disabled on fields you want to exclude from parsley,
# multi select2s for example have a bug with parsley, and it is recommended that data-parsley-disabled is applied
window.ParsleyConfig = window.ParsleyConfig || {}
$.extend(window.ParsleyConfig , {
  excluded: 'input[type=button], input[type=submit], input[type=reset], [disabled], :hidden, [data-parsley-disabled]',

  # Some fields include template variable insertion, which wraps a div around the field. In that case,
  # we want the errors container to go after the wrapper, not inside it.
  errorsContainer: (parsleyField) ->
    $fieldElement = parsleyField.$element
    $parent = $fieldElement.parent()

    if $parent.attr("class") == "template-variable-insert-wrapper"
      return $parent
    else
      return
})

Parsley.addValidator('http', (value, requirement) ->
  return validator?.isValidHttp(value)
)

Parsley.addValidator('https', (value, requirement) ->
  return validator?.isValidHttps(value)
)

Parsley.addValidator('validips', (value, requirement) ->
  if value == ""
    return
  $addresses = value.split(",")
  isValidIP = (element, index, array) ->
    return validator?.isValidIP(element)
  return $addresses.every(isValidIP)
)

Parsley.addValidator('protocolurl', (value, requirement) ->
  validator?.isValidProtocolUrl(value)
)

Parsley.addValidator('deeplink', (value, requirement) ->
  validator?.isValidDeepLink(value)
)

Parsley.addValidator('navigationuri', (value, requirement) ->
  validator?.isValidNavigationUri(value)
)

# Expects value and requirement to be date strings (e.g. "2015-12-25")
Parsley.addValidator('after', (value, requirement) ->
  return Date.parse(value) >= Date.parse(requirement)
)

Parsley.addValidator('incomplete', (value, requirement) ->
  return value? && "" != value
)

# Assuming a structure like
# <input type=text data-parsley-multiplied_max="30|#myselect"></input>
# <select id="myselect">
#   <option value="1"></option>
#   <option value="10" selected="true"></option>
#   <option value="100"></option>
# </select>
# this validator ensures that the input value * the selected option is <= 30 (so in the above example, the input <= 3)
Parsley.addValidator('multiplied_max', (value, requirement, thirdThing) ->
  [multipliedMax, multiplierSelector] = requirement.split("|")
  return parseInt(value) * parseInt($(multiplierSelector).val()) <= parseInt(multipliedMax)
)

Parsley.addValidator('multiplied_min', (value, requirement, thirdThing) ->
  [multipliedMin, multiplierSelector] = requirement.split("|")
  return parseInt(value) * parseInt($(multiplierSelector).val()) >= parseInt(multipliedMin)
)

Parsley.addValidator('comma_delimited_validator', (value, requirement, thirdThing) ->
  values = value.split(',')
  underlyingValidator = Parsley._validatorRegistry.validators[requirement]
  if !underlyingValidator?
    return true

  for value in values
    value = value.trim()
    if value != '' && !underlyingValidator.fn(value)
      return false
  return true
)

Parsley.addValidator('engagement_report_name', (value, requirement) ->
  return validator?.isValidEngagementReportName(value)
)

Parsley.addValidator('multiple_emails', (value, requirement) ->
  for email in value
    if !validator?.isValidEmail(email)
      return false

  return true
)

Parsley.addValidator('multiple_notification_emails', (value, requirement) ->
  for email in value
    if !validator?.isValidEmail(email) && email != notificationConstants.ALL_ADMINS_TAG && email != notificationConstants.ALL_DEVS_TAG
      return false

  return true
)

Parsley.addValidator('ascii_email', (value, requirement) ->
  return validator?.isValidEmail(value, true)
)

Parsley.addValidator('no_api_trigger_properties', (value, requirement) ->
  return validator?.liquidWithoutApiTriggeredProperties(value)
)

Parsley.addValidator('no_api_trigger_properties_no_pep', (value, requirement) ->
  return validator?.liquidWithoutApiTriggeredProperties(value)
)

Parsley.addValidator('no_api_trigger_properties_in_iams', (value, requirement) ->
  return validator?.liquidWithoutApiTriggeredProperties(value)
)

Parsley.addValidator('no_sms_inbound_trigger_properties', (value, requirement) ->
  return validator?.liquidWithoutSmsInboundProperties(value)
)

Parsley.addValidator('no_sms_inbound_trigger_properties_in_iams', (value, requirement) ->
  return validator?.liquidWithoutSmsInboundProperties(value)
)

Parsley.addValidator('no_whats_app_inbound_trigger_properties', (value, requirement) ->
  return validator?.liquidWithoutWhatsAppInboundProperties(value)
)

Parsley.addValidator('no_whats_app_inbound_trigger_properties_in_iams', (value, requirement) ->
  return validator?.liquidWithoutWhatsAppInboundProperties(value)
)

Parsley.addValidator('no_context_properties', (value, requirement) ->
  return validator?.liquidWithoutContextProperties(value)
)

Parsley.addValidator('no_context_properties_in_iams', (value, requirement) ->
  return validator?.liquidWithoutContextProperties(value)
)

Parsley.addValidator('no_event_properties', (value, requirement) ->
  return validator?.liquidWithoutEventProperties(value)
)

Parsley.addValidator('no_event_properties_iams', (value, requirement) ->
  return validator?.liquidWithoutEventProperties(value)
)

Parsley.addValidator('iam_self_hosted_zip_url', (value, requirement) ->
  if !value? || value == ""
    return true
  valid = true
  $.ajax(
    acceptFailure: true
    url: "/apps/validate_self_hosted_zip_url"
    dataType: "json"
    type: "POST"
    contentType: "application/json"
    data: JSON.stringify(zip_url: value)
  ).done((data) =>
    valid = data?.zip_url_valid
  )

  return valid
)

Parsley.addCatalog('en', {
  defaultMessage: i18n.t("invalid-field-default-warn-message", "This field seems to be invalid.")
  type: {
    email: i18n.t("should-be-a-valid-email-message", "This field should be a valid email.")
    url: i18n.t("should-be-a-valid-url-message", "This field should be a valid URL.")
    urlstrict: i18n.t("should-be-a-valid-url-strict-message", "This field should be a valid URL.")
    number: i18n.t("should-be-a-valid-number-message", "This field should be a valid number.")
    integer: i18n.t("should-be-a-valid-integer-message", "This field should be a valid integer.")
    digits: i18n.t("should-be-a-valid-whole-number-message", "This field should be a valid whole number.")
    dateIso: i18n.t("should-be-a-valid-date-message", "This field should be a valid date (YYYY-MM-DD).")
    alphanum: i18n.t("alphanumeric-value-required-message", "This field should be alphanumeric.")
    phone: i18n.t("should-be-a-valid-phone-number-message", "This field should be a valid phone number.")
  }
  notnull: i18n.t("should-not-be-null-message", "This field should not be null.")
  notblank: i18n.t("should-not-be-blank-message", "This field should not be blank.")
  required: i18n.t("field-is-required-message", "This field is required.")
  regexp: i18n.t("required-pattern-not-matched-message", "This field does not match the required pattern.")
  min: i18n.t("greater-than-or-equal-to-message", "This field should be greater than or equal to %s.")
  max: i18n.t("less-than-or-equal-to-message", "This field should be less than or equal to %s.")
  range: i18n.t("required-range-message", "This value should be between %s and %s.")
  minlength: i18n.t("minimum-length-requirement-message", "This value is too short. It should have %s characters or more.")
  maxlength: i18n.t("maximum-length-requirement-message", "This value is too long. It should have %s characters or less.")
  rangelength: i18n.t("range-length-requirement-message", "This field's value should be between %s and %s characters long.")
  mincheck: i18n.t("minimum-check-message", "You must select at least %s choices.")
  maxcheck: i18n.t("maximum-check-message", "You must select at most %s choices.")
  rangecheck: i18n.t("range-check-message", "You must select between %s and %s choices.")
  equalto: i18n.t("field-must-match", "This field must match.")
  http: i18n.t("should-be-a-valid-http-url-message", "This field should be a valid http or https URL.")
  https: i18n.t("should-be-a-valid-https-url-message", "This field should be a valid https URL.")
  protocolurl: i18n.t("should-be-a-valid-protocol-url-message", "This field should be a valid web or protocol URL, such as http://example.com.")
  deeplink: i18n.t("should-be-a-valid-deep-link-url-message", "This field should be a valid deep-link URL, such as myapp://deep/link.")
  user_templated_validator: i18n.t("should-have-valid-user-templating-message", "This field should have valid user templating.")
  validips: i18n.t("invalid-ip-addresses-or-cidr-ranges-message", "One or more IP addresses or CIDR ranges are invalid. Examples of valid IP addresses are: 10.0.5.1, 192.0.2.0/24")
  after: i18n.t("should-be-on-or-after-the-beginning-date-message", "This field should be on or after the beginning date")
  navigationuri: i18n.t("navigation-uri-must-start-and-end-with-message", "Navigation URI must start with '/' and end '.xaml'.")
  incomplete: i18n.t("field-is-incomplete-message", "This field is incomplete")
  engagement_report_name: i18n.t("field-should-only-contain-message", "This field should only contain letters, numbers, underscores, and hyphens.")
  multiple_emails: i18n.t("invalid-emails-message", "One or more emails are invalid.")
  multiple_notification_emails: i18n.t("invalid-notification-emails-message", "One or more emails are invalid.")
  iam_self_hosted_zip_url: i18n.t("url-does-not-point-to-a-valid-zip-file-message", "The URL does not point to a valid zip file.")
  ascii_email: i18n.t("should-be-a-valid-email-address-message", "This field should be a valid email address.")
  no_sms_inbound_trigger_properties_in_iams: i18n.t("no-sms-inbound-trigger-properties-in-iams-message", "SMS Properties may not be used in In-App Messages.")
  no_whats_app_inbound_trigger_properties_in_iams: i18n.t("no-whats-app-inbound-trigger-properties-in-iams-message", "WhatsApp Properties may not be used in In-App Messages.")
  no_sms_inbound_trigger_properties: i18n.t("no-sms-inbound-trigger-properties-message", "There are no SMS properties available to reference in this Canvas path.")
  no_whats_app_inbound_trigger_properties: i18n.t("no-whats-app-inbound-trigger-properties-message", "WhatsApp properties may only be used in action-based Canvas steps or in the first step of action-based Canvases.")
  no_api_trigger_properties_in_iams: i18n.t("no-api-trigger-properties-in-iams-message", "API Trigger Properties may not be used in In-App Messages.")
  no_api_trigger_properties_no_pep: i18n.t("no-api-trigger-properties-in-canvas-steps-message", "API Trigger Properties cannot be used in Canvas steps.")
  no_api_trigger_properties:
    if featureFlipper.featureOn("enable_persistent_event_entry_properties")
    then i18n.t("no-api-trigger-properties-in-canvas-steps-message-feature-on", "API Trigger Properties cannot be used in Canvas steps. \
      Use Canvas Entry Properties to personalize this message with the properties of the API call that entered the user into this Canvas.")
    else i18n.t("no-api-trigger-properties-in-canvas-steps-message-feature-off", "API Trigger Properties cannot be used in Canvas steps.")
  no_context_properties:
    if featureFlipper.featureOn("enable_persistent_event_entry_properties")
    then PEP_FLAG_ON_CEP_ERROR
    else PEP_FLAG_OFF_CEP_ERROR
  no_event_properties: i18n.t(
    "no-event-properties-message-steps",
    "No available event properties.  Add an Action Paths step before this Message step, then connect this step to the action group that triggers the event or purchase you want to reference."
  )
  no_event_properties_iams: i18n.t(
    "no-event-properties-iam-message-steps",
    "To use event properties, set in-app message trigger to a custom event or purchase."
  )
  no_context_properties_in_iams:
    if featureFlipper.featureOn("enable_persistent_event_entry_properties")
    then PEP_FLAG_ON_CEP_ERROR
    else PEP_FLAG_OFF_CEP_ERROR
}, true)
