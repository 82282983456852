# @owner @Appboy/email

# New email validation, based upon from https://github.com/afair/email_address and its implementation
# in lib/shared/appboy/communication/email_address_validator.rb

import liquidTemplateConstants from 'lib/constants/liquid-template-constants'

export class EmailValidator

  # this is in lieu of /[\p{L}\p{N}]/ since IE does not support unicode character classes
  UNICODE_LETTER_OR_NUMBER_REGEX = ///[
    \u0030-\u0039\u0041-\u005a\u0061-\u007a\u00b2-\u00b3\u00b9-\u00ba\u00bc-\u00be\u00c0-\u00d6\u00d8-\u00f6
    \u00f8-\u02c1\u02c6-\u02d1\u02e0-\u02e4\u0370-\u0374\u0376-\u0377\u037a-\u037d\u0388-\u038a\u038e-\u03a1
    \u03a3-\u03f5\u03f7-\u0481\u048a-\u052f\u0531-\u0556\u0560-\u0588\u05d0-\u05ea\u05ef-\u05f2\u0620-\u064a
    \u0660-\u0669\u066e-\u066f\u0671-\u06d3\u06e5-\u06e6\u06ee-\u06fc\u0712-\u072f\u074d-\u07a5\u07c0-\u07ea
    \u07f4-\u07f5\u0800-\u0815\u0840-\u0858\u0860-\u086a\u08a0-\u08b4\u08b6-\u08bd\u0904-\u0939\u0958-\u0961
    \u0966-\u096f\u0971-\u0980\u0985-\u098c\u098f-\u0990\u0993-\u09a8\u09aa-\u09b0\u09b6-\u09b9\u09dc-\u09dd
    \u09df-\u09e1\u09e6-\u09f1\u09f4-\u09f9\u0a05-\u0a0a\u0a0f-\u0a10\u0a13-\u0a28\u0a2a-\u0a30\u0a32-\u0a33
    \u0a35-\u0a36\u0a38-\u0a39\u0a59-\u0a5c\u0a66-\u0a6f\u0a72-\u0a74\u0a85-\u0a8d\u0a8f-\u0a91\u0a93-\u0aa8
    \u0aaa-\u0ab0\u0ab2-\u0ab3\u0ab5-\u0ab9\u0ae0-\u0ae1\u0ae6-\u0aef\u0b05-\u0b0c\u0b0f-\u0b10\u0b13-\u0b28
    \u0b2a-\u0b30\u0b32-\u0b33\u0b35-\u0b39\u0b5c-\u0b5d\u0b5f-\u0b61\u0b66-\u0b6f\u0b71-\u0b77\u0b85-\u0b8a
    \u0b8e-\u0b90\u0b92-\u0b95\u0b99-\u0b9a\u0b9e-\u0b9f\u0ba3-\u0ba4\u0ba8-\u0baa\u0bae-\u0bb9\u0be6-\u0bf2
    \u0c05-\u0c0c\u0c0e-\u0c10\u0c12-\u0c28\u0c2a-\u0c39\u0c58-\u0c5a\u0c60-\u0c61\u0c66-\u0c6f\u0c78-\u0c7e
    \u0c85-\u0c8c\u0c8e-\u0c90\u0c92-\u0ca8\u0caa-\u0cb3\u0cb5-\u0cb9\u0ce0-\u0ce1\u0ce6-\u0cef\u0cf1-\u0cf2
    \u0d05-\u0d0c\u0d0e-\u0d10\u0d12-\u0d3a\u0d54-\u0d56\u0d58-\u0d61\u0d66-\u0d78\u0d7a-\u0d7f\u0d85-\u0d96
    \u0d9a-\u0db1\u0db3-\u0dbb\u0dc0-\u0dc6\u0de6-\u0def\u0e01-\u0e30\u0e32-\u0e33\u0e40-\u0e46\u0e50-\u0e59
    \u0e81-\u0e82\u0e86-\u0e8a\u0e8c-\u0ea3\u0ea7-\u0eb0\u0eb2-\u0eb3\u0ec0-\u0ec4\u0ed0-\u0ed9\u0edc-\u0edf
    \u0f20-\u0f33\u0f40-\u0f47\u0f49-\u0f6c\u0f88-\u0f8c\u1000-\u102a\u103f-\u1049\u1050-\u1055\u105a-\u105d
    \u1065-\u1066\u106e-\u1070\u1075-\u1081\u1090-\u1099\u10a0-\u10c5\u10d0-\u10fa\u10fc-\u1248\u124a-\u124d
    \u1250-\u1256\u125a-\u125d\u1260-\u1288\u128a-\u128d\u1290-\u12b0\u12b2-\u12b5\u12b8-\u12be\u12c2-\u12c5
    \u12c8-\u12d6\u12d8-\u1310\u1312-\u1315\u1318-\u135a\u1369-\u137c\u1380-\u138f\u13a0-\u13f5\u13f8-\u13fd
    \u1401-\u166c\u166f-\u167f\u1681-\u169a\u16a0-\u16ea\u16ee-\u16f8\u1700-\u170c\u170e-\u1711\u1720-\u1731
    \u1740-\u1751\u1760-\u176c\u176e-\u1770\u1780-\u17b3\u17e0-\u17e9\u17f0-\u17f9\u1810-\u1819\u1820-\u1878
    \u1880-\u1884\u1887-\u18a8\u18b0-\u18f5\u1900-\u191e\u1946-\u196d\u1970-\u1974\u1980-\u19ab\u19b0-\u19c9
    \u19d0-\u19da\u1a00-\u1a16\u1a20-\u1a54\u1a80-\u1a89\u1a90-\u1a99\u1b05-\u1b33\u1b45-\u1b4b\u1b50-\u1b59
    \u1b83-\u1ba0\u1bae-\u1be5\u1c00-\u1c23\u1c40-\u1c49\u1c4d-\u1c7d\u1c80-\u1c88\u1c90-\u1cba\u1cbd-\u1cbf
    \u1ce9-\u1cec\u1cee-\u1cf3\u1cf5-\u1cf6\u1d00-\u1dbf\u1e00-\u1f15\u1f18-\u1f1d\u1f20-\u1f45\u1f48-\u1f4d
    \u1f50-\u1f57\u1f5f-\u1f7d\u1f80-\u1fb4\u1fb6-\u1fbc\u1fc2-\u1fc4\u1fc6-\u1fcc\u1fd0-\u1fd3\u1fd6-\u1fdb
    \u1fe0-\u1fec\u1ff2-\u1ff4\u1ff6-\u1ffc\u2070-\u2071\u2074-\u2079\u207f-\u2089\u2090-\u209c\u210a-\u2113
    \u2119-\u211d\u212a-\u212d\u212f-\u2139\u213c-\u213f\u2145-\u2149\u2150-\u2189\u2460-\u249b\u24ea-\u24ff
    \u2776-\u2793\u2c00-\u2c2e\u2c30-\u2c5e\u2c60-\u2ce4\u2ceb-\u2cee\u2cf2-\u2cf3\u2d00-\u2d25\u2d30-\u2d67
    \u2d80-\u2d96\u2da0-\u2da6\u2da8-\u2dae\u2db0-\u2db6\u2db8-\u2dbe\u2dc0-\u2dc6\u2dc8-\u2dce\u2dd0-\u2dd6
    \u2dd8-\u2dde\u3005-\u3007\u3021-\u3029\u3031-\u3035\u3038-\u303c\u3041-\u3096\u309d-\u309f\u30a1-\u30fa
    \u30fc-\u30ff\u3105-\u312f\u3131-\u318e\u3192-\u3195\u31a0-\u31ba\u31f0-\u31ff\u3220-\u3229\u3248-\u324f
    \u3251-\u325f\u3280-\u3289\u32b1-\u32bf\u3400-\u4db5\u4e00-\u9fef\ua000-\ua48c\ua4d0-\ua4fd\ua500-\ua60c
    \ua610-\ua62b\ua640-\ua66e\ua67f-\ua69d\ua6a0-\ua6ef\ua717-\ua71f\ua722-\ua788\ua78b-\ua7bf\ua7c2-\ua7c6
    \ua7f7-\ua801\ua803-\ua805\ua807-\ua80a\ua80c-\ua822\ua830-\ua835\ua840-\ua873\ua882-\ua8b3\ua8d0-\ua8d9
    \ua8f2-\ua8f7\ua8fd-\ua8fe\ua900-\ua925\ua930-\ua946\ua960-\ua97c\ua984-\ua9b2\ua9cf-\ua9d9\ua9e0-\ua9e4
    \ua9e6-\ua9fe\uaa00-\uaa28\uaa40-\uaa42\uaa44-\uaa4b\uaa50-\uaa59\uaa60-\uaa76\uaa7e-\uaaaf\uaab5-\uaab6
    \uaab9-\uaabd\uaadb-\uaadd\uaae0-\uaaea\uaaf2-\uaaf4\uab01-\uab06\uab09-\uab0e\uab11-\uab16\uab20-\uab26
    \uab28-\uab2e\uab30-\uab5a\uab5c-\uab67\uab70-\uabe2\uabf0-\uabf9\uac00-\ud7a3\ud7b0-\ud7c6\ud7cb-\ud7fb
    \uf900-\ufa6d\ufa70-\ufad9\ufb00-\ufb06\ufb13-\ufb17\ufb1f-\ufb28\ufb2a-\ufb36\ufb38-\ufb3c\ufb40-\ufb41
    \ufb43-\ufb44\ufb46-\ufbb1\ufbd3-\ufd3d\ufd50-\ufd8f\ufd92-\ufdc7\ufdf0-\ufdfb\ufe70-\ufe74\ufe76-\ufefc
    \uff10-\uff19\uff21-\uff3a\uff41-\uff5a\uff66-\uffbe\uffc2-\uffc7\uffca-\uffcf\uffd2-\uffd7\uffda-\uffdc
    \u00aa\u00b5\u02ec\u02ee\u037f\u0386\u038c\u0559\u06d5\u06ff\u0710\u07b1\u07fa\u081a\u0824\u0828\u093d\u0950\u09b2
    \u09bd\u09ce\u09fc\u0a5e\u0abd\u0ad0\u0af9\u0b3d\u0b83\u0b9c\u0bd0\u0c3d\u0c80\u0cbd\u0cde\u0d3d\u0d4e\u0dbd\u0e84
    \u0ea5\u0ebd\u0ec6\u0f00\u1061\u108e\u10c7\u10cd\u1258\u12c0\u17d7\u17dc\u18aa\u1aa7\u1cfa\u1f59\u1f5b\u1f5d\u1fbe
    \u2102\u2107\u2115\u2124\u2126\u2128\u214e\u2cfd\u2d27\u2d2d\u2d6f\u2e2f\ua8fb\uaa7a\uaab1\uaac0\uaac2\ufb1d\ufb3e
  ]///
  EMAIL_ADDRESS_LOCAL_DOMAIN_SPLIT_REGEX = /(.+)@(.+)/
  EMAIL_LOCAL_PART_VALIDATION_REGEX = ///^ (?:#{UNICODE_LETTER_OR_NUMBER_REGEX.source}|_) (?: (?:#{UNICODE_LETTER_OR_NUMBER_REGEX.source}|[_\-\.\+\'])* (?:#{UNICODE_LETTER_OR_NUMBER_REGEX.source}|[_\-]) )? $///
  DOMAIN_LABEL_REGEX = /[\w\d](?:[\w\d-]{0,61}[\w\d])?/
  EMAIL_DOMAIN_PART_VALIDATION_REGEX = ///^ #{DOMAIN_LABEL_REGEX.source} (?:\.#{DOMAIN_LABEL_REGEX.source})+ $///i
  MAX_LOCAL_PART_LENGTH = 64
  MAX_DOMAIN_PART_LENGTH = 253
  MAX_EMAIL_LENGTH = 254

  @isValidEmail: (value) ->
    value = value.trim()
    if '' == value
      return false
    if value.match(EMAIL_ADDRESS_LOCAL_DOMAIN_SPLIT_REGEX)
      matches = value.match(EMAIL_ADDRESS_LOCAL_DOMAIN_SPLIT_REGEX)
      local = matches[1]
      domain = matches[2]

      return !!(isValidLocal(local, domain) \
        && isValidDomain(domain) \
        && getUnicodePointCount(local + domain) < MAX_EMAIL_LENGTH)
    return false

  # "private"

  # A valid fully qualified domain name has at least two dot-separated labels, where each label is between 1-63
  # characters long. The label must start and end with a letter or number and may include any number of dashes.
  # Fully numeric TLDs are invalid. Additionally, the max length of a FQDN is 253 characters
  isValidDomain = (domainName) ->
    return domainName.length <= MAX_DOMAIN_PART_LENGTH \
      && !domainName.match(/\.\d+$/) \
      && domainName.match(EMAIL_DOMAIN_PART_VALIDATION_REGEX)

  isValidLocal = (value, domain) ->
    if isMsEmailDomain(domain)
      return value.match(/^\w[\-\w]*(?:\.[\-\w]+)*$/i)
    else
      return getUnicodePointCount(value) <= MAX_LOCAL_PART_LENGTH && value.match(EMAIL_LOCAL_PART_VALIDATION_REGEX)

  isMsEmailDomain = (domainName) ->
    return domainName.match(/^(?:.+\.)?(msn|hotmail|outlook|live)(\.\w+)?\.\w{2,}$/i)

  # unicode char length,
  # from  https://medium.com/@tanishiking/count-the-number-of-unicode-code-points-in-javascript-on-cross-browser-62c32b8d919c
  # The approach in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length#Unicode
  # looks more elegant, but requires use of the spread operator, which is not possible here and isn't compatible with IE11
  getUnicodePointCount = (str) ->
    count = 0;
    i = 0
    while i < str.length
      count++
      # code is i-th 16-bit code unit.
      code = str.charCodeAt(i)
      if (0xD800 <= code && code <= 0xDBFF)
        # If i-th 16-bit code unit is in high-surrogates,
        # skip the following 16-bit (low-surrogate).
        i++
      i++
    return count
